const RouteConstants: any = {
    root: '/',
    login: '/login',
    update_password: '/update-password',
    stories: '/stories',
    microcopy: '/microcopy',
    tooltips: '/tooltips',
    data: '/data',
    user: 'user',
    requests: '/requests',
    companies: '/companies',
    roles: '/roles',
    profile: '/profile',
    settings: '/settings',
    something_went_wrong: '/something-went-wrong',
    error: '/error',
    not_found: '/not-found',
}

export { RouteConstants };