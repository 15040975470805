import { useState } from "react";
import MyContext  from "./MyContext";

const ContextState = (props: any) =>{
    const [openComponent,  setOpenComponent] = useState("Microcopy");
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [selectedDataEdit, setSelectedDataEdit] = useState();
    const [isbackpress, setIsbackpress] = useState(false);
    const [carouselOriginalData, setCarouselOriginalData] = useState();
    return(
        <MyContext.Provider value={{openComponent,setOpenComponent, openEdit, setOpenEdit,selectedDataEdit, setSelectedDataEdit, isbackpress, setIsbackpress, carouselOriginalData, setCarouselOriginalData}}>
            {props.children}
        </MyContext.Provider>

    );
}

export default ContextState;