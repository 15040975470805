const APIS: any = {
    ADMIN: {
        LOGIN: '/users/login/',
        LOGOUT: '/users/logout/',
    },
    USERS: {
        GET_LOGGED_USER: '/users/me',
        UPDATE_LOGGED_USER: '/users/update/my/details/',
        GET_ALL_USERS: '/users/all/',
        GET_NEW_ACCESS_TOKEN: '/users/token-refresh/',
        ROLE_PERMISSION: '/users/role_permission_list/',
        USER_GROUP_PERMISSION: '/users/get_company_group_role/',
    },
    SETTINGS: {
        GET_ALL_SETTINGS: '/users/settings/details/',
        USER_SETTINGS: '/users/settings/',
    },
    DATA_POINTS: {
        GET_ALL_DATA: '/datapoints/datapage',
        GET_DROPDOWN: '/datapoints/dropdown',
        GET_ADDITIONAL_FIELDS: '/story/fielddropdown',
        GET_STATUSES: '/users/statusdropdown/',
        GET_DATA_FOR_DOWNLOAD: '/datapoints/getdata/state/',
        ADD_DATA: '/datapoints/adddata'
    },
    STORIES: {
        GET_ALL_STORIES: '/story/getallstory',
        ADD_STORY: '/story/loadstory',
        EDIT_STORY: '/story/updatestory/',
        DELETE_STORY: '/story/deletestory/',
        APPROVE_STORY: '/story/updatestorystatus',
        COMPARE_STORY: '/adminstory/comparestory/',
        GET_FEATURED_STORIES: '/story/featuredstory',
    },
    CIF: {
        GET_INOUTFLOW_DATA: '/cif/inflowoutflow',
        GET_EH_GROWTH_DATA: '/cif/ehgrowth',
        GET_METRIC_BREAKDOWN: '/cif/metricbreakdown',
        GET_GEO_SPECIFIC_DATA: '/cif/headingtable',
        GET_CORE_SOLUTIONS_DATA: '/cif/bubblegraph',
        SEND_EMAIL: '/cif/request-email',
    },
    ANALYTICS: {
        USERMETRICS: "/analytics/usermetrics"
    },
    MICROCOPIES: {
        GET_ALL_MICROCOPIES_DATA: '/microcopy/getallmicrocopies',
        UPDATE_MICROCOPY: '/microcopy/editmicrocopies',
        APPROVE_MICROCOPY: '/microcopy/approvemicrocopies',
        COMPARE_MICROCOPY: '/microcopy/comparemicrocopies/'
    },
    TOOLTIPS: {
        GET_ALL_TOOLTIPS: '/tooltips/getalltooltips',
        UPDATE_TOOLTIP: '/tooltips/edittooltip',
        APPROVE_TOOLTIP: '/tooltips/approvetooltip',
        COMPARETOOLTIP: '/tooltips/comparetooltips/'
    },
    REQUESTS: {
        GET_ALL_REQUESTS_DATA: 'users/allstagingusers/',
        POST_NEW_REQUEST: 'users/admininvite/',
        APPROVE_USER: 'users/userapproval/'
    },
    DROPDOWN: {
        GET_COMPANYTYPE_DATA: 'users/getcompanytype/',
        GET_USERGROUP_DATA: 'users/getusergroup/',
        GET_USERTYPE_DATA: 'users/getusertype/'
    },
    COMPANY: {
        GET_MAP: '/company/getmap',
        GET_COMPANIES: '/company/getallcompanies',
        GET_COMPANIES_DROPDOWN: '/company/companydropdown',
    }
}

export { APIS };