import { atom } from "recoil";

export interface Stories {
    page: number;
    storiesPerPage: number;
    totalStories: number;
    totalPages: number;
    stories: any[];
}

export const storiesState = atom({
    key: 'storiesData',
    default: {} as any,
});

export const microState = atom({
    key: 'microData',
    default: {} as any,
})
