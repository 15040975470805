import { atom } from "recoil";

interface Dropdown {
    states: any[],
    districts: any[],
    statuses: any[],
    additionalFields: any[],
    companies: any[],
    companyType: any[],
    userGroup: any[],
}

export const dropdownState = atom({
    key: 'dropdown',
    default: {} as Dropdown,
});

export const datapointsState = atom({
    key: 'datapoints',
    default: [] as any,
});
