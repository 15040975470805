import { atom } from 'recoil';

export interface IBranch {
    branch_id: string,
    district: string,
    pincode: number | null,
    company_name: string | null,
    latitude: number,
    longitude: number,
    address: string,
    google_map_link: string,
    branch_status: string,
    date_of_opening: string | null,
    date_of_closing: string | null,
    performance: string,
    color: string,
    geo_hierarchy: number[],
}

export interface ICompany {
    company_id: number,
    branches: IBranch[],
    branch_count: number,
    company_name: string,
    industry: string,
    headquarters: string,
    founded_in: string,
    website: string,
    number_of_location: 3,
    description: string,
    competitors: null | string,
    investors: null | string,
    added_by: string,
    added_at: Date,
    color: string
}

export interface companyProps {
    companies: ICompany[],
    pagination: {
        page: number;
        storiesPerPage: number;
        totalStories: number;
        totalPages: number;
    }
}

const companyState = atom({
    key: 'company',
    default: {} as companyProps
});

export { companyState };